/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { AgentStatService } from 'src/app/features/base/services/agent-stat.service';
import { UserActions } from '../../../../../../features/base/state-management/user/user.actions';
import { UserReducer } from '../../../../../../features/base/state-management/user/user.reducer';
import { Agent } from '../../../../../../features/base/state-management/user/user.state';
import { ToolBarTickerComponent } from '../tool-bar-ticker/tool-bar-ticker.component';
import { SnackBarActions } from '../../../../../../features/base/state-management/snack-bar/snack-bar.actions';
import { HomeLogoutDialogComponent } from '../../../../../../features/home/ui/components/home-logout-dialog/home-logout-dialog.component';
import { BaseComponent } from '../../../../../../core/components/base.component';
import { MatDialog } from '@angular/material/dialog';
import { HomePauseDialogComponent } from '../../../../../../features/home/ui/components/home-pause-dialog/home-pause-dialog.component';
import { withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-tool-bar-timer',
  templateUrl: './tool-bar-timer.component.html',
  styleUrls: ['./tool-bar-timer.component.scss'],
})
export class ToolBarTimerComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  timerStatus = 'stopped';
  isLoading = this.userReducer.selectIsLoading();
  isTimersSet = false;
  lastPauseReason?: string;

  @Input()
  agent: Agent;

  @Output()
  onStart = new EventEmitter();

  @Output()
  onPause = new EventEmitter();

  @Output()
  onResume = new EventEmitter();

  @Output()
  onEnd = new EventEmitter();

  @ViewChild('mainTicker') mainTicker: ToolBarTickerComponent;
  @ViewChild('breakTicker') breakTicker: ToolBarTickerComponent;

  constructor(
    protected dialog: MatDialog,
    protected changeDetectorRef: ChangeDetectorRef,
    protected agentStatService: AgentStatService,
    protected userActions: UserActions,
    protected userReducer: UserReducer,
    protected snackBarActions: SnackBarActions
  ) {
    super();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      const agentLatestStat = this.agent.agentLatestStat;
      this.lastPauseReason = agentLatestStat.lastPauseReason;
      // start timer
      if (agentLatestStat?.entityType === 'shift_start') {
        this.timerStatus = 'started';
        this.mainTicker.startTimer(agentLatestStat?.mainSeconds);
      } else if (agentLatestStat?.entityType === 'shift_pause') {
        this.timerStatus = 'paused';
        this.agentStatService.setSecondsBetweenPause(
          agentLatestStat?.pauseSeconds
        );
        this.mainTicker.setSeconds(agentLatestStat?.mainSeconds);
        this.breakTicker.startTimer(agentLatestStat?.pauseSeconds);
        this.agentStatService.startTimerBetweenPause();
      } else if (agentLatestStat?.entityType === 'shift_resume') {
        this.timerStatus = 'started';
        this.mainTicker.startTimer(agentLatestStat?.mainSeconds);

        if (Number(agentLatestStat?.lastPauseSeconds) !== 0) {
          this.agentStatService.setSecondsBetweenPause(
            agentLatestStat?.lastPauseSeconds
          );
          this.agentStatService.startTimerBetweenPause();
        }
      }

      this.isTimersSet = true;
      this.changeDetectorRef.markForCheck();
    });
  }

  startMainTimer() {
    this.recordAgentShiftLog({
      entityType: 'shift_start',
    });

    this.timerStatus = 'started';
    this.mainTicker.startTimer();
    this.changeDetectorRef.markForCheck();
  }

  endMainTimer() {
    this.subscription = this.dialog
      .open(HomeLogoutDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.recordAgentShiftLog({
            entityType: 'shift_end',
          });
          this.timerStatus = 'stopped';
          this.mainTicker.clearTimer();
          this.breakTicker.clearTimer();
          this.changeDetectorRef.markForCheck();
        }
      });
  }

  pauseBreakTimer() {
    if (!this.isTimersSet) {
      return;
    }

    const subscription = this.dialog
      .open(HomePauseDialogComponent)
      .afterClosed()
      .pipe(withLatestFrom(this.agentStatService.checkTimerBetweenPause()))
      .subscribe(([reason, tooOften]) => {
        if (!reason) {
          subscription.unsubscribe();

          return;
        }

        const isLunch = reason === 'lunch';

        if (
          isLunch &&
          (this.lastPauseReason === 'lunch' ||
            this.lastPauseReason === 'break') &&
          !tooOften
        ) {
          this.showPauseLimitMessage();
          return;
        }

        if (!isLunch && !tooOften) {
          this.showPauseLimitMessage();
          return;
        }

        this.recordAgentShiftLog({
          entityType: 'shift_pause',
          reason,
        });

        this.lastPauseReason = reason;
        this.timerStatus = 'paused';
        this.mainTicker.pauseTimer();
        this.breakTicker.startTimer();
        this.agentStatService.startTimerBetweenPause();
        this.changeDetectorRef.markForCheck();
      });
  }

  resumeBreakTimer() {
    this.recordAgentShiftLog({
      entityType: 'shift_resume',
    });

    this.timerStatus = 'started';
    this.mainTicker.startTimer();
    this.breakTicker.clearTimer();
    this.changeDetectorRef.markForCheck();
  }

  recordAgentShiftLog(payload) {
    new this.userActions.SetAgentShiftAction(payload).dispatch();
  }

  showPauseLimitMessage() {
    this.agentStatService.getHMS().subscribe((hms) => {
      new this.snackBarActions.ShowSuccessAction({
        message: `You can only pause twice per hour! Left: ${hms}`,
      }).dispatch();
    });
  }
}
