/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { concatMap } from 'rxjs/operators';
import { ConfigPayload } from './config.payload';
import { ConfigActions } from './config.actions';
import { AbstractEffects } from '../../../../core/state-management/abstract-effects';
import { SseActions } from '../sse/sse.actions';

@Injectable()
export class ConfigEffects extends AbstractEffects<ConfigPayload> {
  setConfig$ = createEffect(() =>
    this.ofType(this.configActions.SetConfigAction.TYPE).pipe(
      concatMap(({ payload, completeActions }) => {
        return [...completeActions];
      })
    )
  );

  registerListener$ = createEffect(() =>
    this.ofType(this.sseActions.StartPing.TYPE).pipe(
      concatMap(({ payload, completeActions }) => {
        return [
          new this.sseActions.RegisterListener({
            eventName: 'sseConfig',
            listener: (configs) => {
              new this.configActions.SetConfigAction(configs).dispatch();
            },
          }),
          ...completeActions,
        ];
      })
    )
  );

  constructor(
    protected actions: Actions,
    protected configActions: ConfigActions,
    protected sseActions: SseActions
  ) {
    super(actions, configActions);
  }
}
