import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimerService {
  private timers = new Map<string, { time: BehaviorSubject<number>; sub: Subscription | null }>();
  private customData = {};

  createTimer(id: string) {
    if (!this.timers.has(id)) {
      this.timers.set(id, { time: new BehaviorSubject(0), sub: null });
    }
  }

  setInitialSeconds(name: string, newSeconds: number) {
    const timer = this.timers.get(name);
    if (timer) {
      timer.time.next(newSeconds);
    }
  }

  startTimer(name: string) {
    const timer = this.timers.get(name);
    if (timer && !timer.sub) {
      timer.sub = interval(1000).subscribe(() => {
        timer.time.next(timer.time.value + 1);
      });
    }
  }

  pauseTimer(name: string) {
    const timer = this.timers.get(name);
    if (timer?.sub) {
      timer.sub.unsubscribe();
      timer.sub = null;
    }
  }

  resetTimer(name: string) {
    this.timers.get(name)?.time.next(0);
  }

  resetFullTimer(id: string) {
    const timer = this.timers.get(id);
    if (timer) {
      if (timer.sub) {
        timer.sub.unsubscribe();
        timer.sub = null;
      }
      timer.time.complete();
      this.timers.delete(id);
    }
  }

  getTime(name: string) {
    return this.timers.get(name)?.time.asObservable();
  }

  clearAllTimers() {
    this.timers.forEach((timer) => {
      if (timer.sub) {
        timer.sub.unsubscribe();
      }
    });
    this.timers.clear();
  }

  setCustomData(key: string, value: string) {
    this.customData[key] = value;
  }
  getCustomData(key: string) {
    return this.customData.hasOwnProperty(key) ? this.customData[key] : null;
  }
}
