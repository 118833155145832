/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractActions } from '../../../../core/state-management/abstract-actions';
import { MassNotificationPayload } from './mass-notification.payload';
import { MassNotification } from './mass-notification.state';

@Injectable()
export class MassNotificationActions extends AbstractActions<MassNotificationPayload> {
  RegisterListener = this.action(`${this.getEntity()} Register Listener`);

  UnRegisterListener = this.action(`${this.getEntity()} Un register Listener`);

  AcceptListener = this.action<MassNotificationPayload>(
    `${this.getEntity()} Accept Listener`
  );

  constructor(store: Store<MassNotification>) {
    super(store);
  }

  getEntity(): string {
    return '[MASS-NOTIFICATION]';
  }
}
