/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { ToolsModule } from './tools/tools.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ShareModule } from './shared/share.module';
import { AdvancedNotificationDialogComponent } from './features/base/state-management/mass-notification/advanced-notification-dialog/advanced-notification-dialog.component';
import { CoreModule } from './core/core.module';
import { ConfigModule } from './core/config/config.module';
import { appConfig } from './core/constants/app.config';
import { InterceptorService } from './core/services/interceptor.service';
import { AppInitService } from './core/services/app-init.service';

const angularMaterial = [MatSnackBarModule, MatDialogModule, MatButtonModule];

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, AdvancedNotificationDialogComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot(),

    ...angularMaterial,

    CoreModule,
    ConfigModule.forRoot(appConfig),
    AppRoutingModule,
    LayoutModule,
    ToolsModule,
    ShareModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB',
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppInitService],
      useFactory: (appInitService: AppInitService) => () =>
        appInitService.init(),
    },
  ],
})
export class AppModule {}
