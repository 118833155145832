/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { Injectable } from '@angular/core';
import { SyncActions } from './sync.actions';
import { SetLastIdPayload, SyncPayload } from './sync.payload';
import { Sync, SyncState } from './sync.state';
import { AbstractAction } from '../../../../core/state-management/abstract-actions';
import { AbstractReducer } from '../../../../core/state-management/abstract-reducer';

@Injectable()
export class SyncReducer extends AbstractReducer<Sync, SyncState, SyncPayload> {
  get storeName(): string {
    return 'sync';
  }

  get reducers(): {
    [p: string]: (
      action: AbstractAction<SyncPayload>,
      state: SyncState
    ) => SyncState;
  } {
    return {
      [this.actions.SetLastId.TYPE]: (
        action: AbstractAction<SetLastIdPayload>,
        state
      ) => ({ ...state, ...action.payload }),
    };
  }

  getInitialState(): SyncState {
    return this.adapter.getInitialState({
      isLoading: false,
      lastId: 0,
    });
  }

  constructor(protected actions: SyncActions) {
    super(actions);
  }
}
