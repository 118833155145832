/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { concatMap, map, switchMap } from 'rxjs/operators';
import { RouterActions } from './router.actions';
import { RouterPayload } from './router.payload';
import { AbstractEffects } from '../../../../core/state-management/abstract-effects';

@Injectable()
export class RouterEffects extends AbstractEffects<RouterPayload> {
  navigate$ = createEffect(() =>
    this.actions.pipe(
      ofType<any>(this.routerActions.NavigateAction.TYPE),
      map((action) => this.parseAction<RouterPayload>(action)),
      switchMap(({ payload, completeActions }) => {
        return from(
          this.router.navigate(payload.commands, payload.extras)
        ).pipe(
          concatMap(() => {
            return [...completeActions];
          })
        );
      })
    )
  );

  constructor(
    protected actions: Actions,
    protected routerActions: RouterActions,
    protected router: Router
  ) {
    super(actions, routerActions);
  }
}
