/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import {
  AbstractEntityState,
  AbstractState,
} from '../../../../core/state-management/abstract-state';

export enum MassNotificationType {
  ADVANCED = 'advanced',
  SIMPLE = 'simple',
}

export interface MassNotification extends AbstractState {
  title: string;
  content: string;
  type: MassNotificationType;
}

export type MassNotificationList = MassNotification[];

export interface MassNotificationState
  extends AbstractEntityState<MassNotification> {}
