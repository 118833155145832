/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import {NgModule} from '@angular/core';
import {LinkifyPipe} from './linkify.pipe';
import {SafeHtmlPipe} from './safeHtml.pipe';

@NgModule({
  declarations: [
    LinkifyPipe,
    SafeHtmlPipe,
  ],
  exports: [
    LinkifyPipe,
    SafeHtmlPipe
  ]
})
export class PipesModule {

}
