/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from '../../../../core/services/abstract.service';

@Injectable({
  providedIn: 'root',
})
export class MassNotificationService extends AbstractService {
  constructor(http: HttpClient) {
    super(http);
  }

  get uri(): string {
    return 'mass-notification';
  }

  accept(id: number) {
    return this.post(`accept`, { id });
  }
}
