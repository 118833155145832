/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  RegisterListenerPayload,
  SsePayload,
  UnRegisterListenerPayload,
} from './sse.payload';
import { SseChannel } from './sse.state';
import { AbstractActions } from '../../../../core/state-management/abstract-actions';

@Injectable()
export class SseActions extends AbstractActions<SsePayload> {
  RegisterListener = this.action<RegisterListenerPayload>(
    `${this.getEntity()} Register sse listener`
  );

  UnRegisterListener = this.action<UnRegisterListenerPayload>(
    `${this.getEntity()} Un-register sse listener`
  );

  RestartListener = this.action(`${this.getEntity()} Restart sse listener`);

  BeforeFireSseAction = this.action<SsePayload>(
    `${this.getEntity()} Before fire sse`
  );

  StartPing = this.action(`${this.getEntity()} Start ping`);

  StopPing = this.action(`${this.getEntity()} Stop ping`);

  getEntity(): string {
    return '[SSE]';
  }

  constructor(store: Store<SseChannel>) {
    super(store);
  }
}
