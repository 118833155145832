/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { Injectable } from '@angular/core';
import { SnackBar, SnackBarState } from './snack-bar.state';
import { AbstractReducer } from '../../../../core/state-management/abstract-reducer';
import { SnackBarPayload } from './snack-bar.payload';
import { AbstractAction } from '../../../../core/state-management/abstract-actions';
import { SnackBarActions } from './snack-bar.actions';

@Injectable()
export class SnackBarReducer extends AbstractReducer<
  SnackBar,
  SnackBarState,
  SnackBarPayload
> {
  get storeName(): string {
    return 'snack-bar';
  }

  get reducers(): {
    [p: string]: (
      action: AbstractAction<SnackBarPayload>,
      state: SnackBarState
    ) => SnackBarState;
  } {
    return {};
  }

  constructor(protected actions: SnackBarActions) {
    super(actions);
  }
}
