/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { Injectable } from '@angular/core';
import { AbstractReducer } from '../../../../core/state-management/abstract-reducer';
import {
  MassNotification,
  MassNotificationState,
} from './mass-notification.state';
import { MassNotificationPayload } from './mass-notification.payload';
import { MassNotificationActions } from './mass-notification.actions';
import { AbstractAction } from '../../../../core/state-management/abstract-actions';

@Injectable()
export class MassNotificationReducer extends AbstractReducer<
  MassNotification,
  MassNotificationState,
  MassNotificationPayload
> {
  constructor(protected actions: MassNotificationActions) {
    super(actions);
  }

  get storeName(): string {
    return 'massNotification';
  }

  get reducers(): {
    [p: string]: (
      action: AbstractAction<MassNotificationPayload>,
      state: MassNotificationState
    ) => MassNotificationState;
  } {
    return {};
  }
}
