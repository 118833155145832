/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { ChangeDetectorRef, Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { TimerService } from '../../../../../../features/base/services/timer.service';

@Component({
  selector: 'app-tool-bar-ticker',
  templateUrl: './tool-bar-ticker.component.html',
  styleUrls: ['./tool-bar-ticker.component.scss']
})
export class ToolBarTickerComponent implements OnInit, OnDestroy {
  timer = 0;
  timerString = '';
  private timeSub!: Subscription;


  @Input()
  timerId: string;

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    protected timerService: TimerService
  ) { }

  ngOnInit(): void {
    this.timerService.createTimer(this.timerId);
    this.timeSub = this.getSeconds().subscribe((value) => {
      this.timerString = this.convertHMS(value);
      this.changeDetectorRef.markForCheck();
    });
  }

  startTimer(startSeconds: number | null = 0) {
    if (!this.timerService.getCustomData('initiated')) {
      this.setSeconds(startSeconds);
      this.timerService.setCustomData('initiated', 'initiated');
    }

    this.timerService.startTimer(this.timerId);
  }

  clearTimer() {
    this.resetTimer();
    this.pauseTimer();
  }

  resetTimer() {
    this.timerService.resetTimer(this.timerId);
  }

  pauseTimer() {
    this.timerService.pauseTimer(this.timerId);
  }

  setSeconds(seconds: number) {
    this.timerService.setInitialSeconds(this.timerId, seconds);
    this.pauseTimer();
  }

  getSeconds() {
    return this.timerService.getTime(this.timerId);
  }

  convertHMS(timerSeconds: number): string {
    const hours   = Math.floor(timerSeconds / 3600);
    const minutes = Math.floor((timerSeconds - (hours * 3600)) / 60);
    const seconds = timerSeconds - (hours * 3600) - (minutes * 60);
    // tslint:disable-next-line:one-variable-per-declaration
    let hourString = hours.toString(), minuteString = minutes.toString(), secondString = seconds.toString();
    if (hours < 10) {
      hourString = '0' + hours;
    }
    if (minutes < 10) {
        minuteString = '0' + minutes;
    }
    if (seconds < 10) {
      secondString = '0' + seconds;
    }

    const result = hourString + ':' + minuteString + ':' + secondString;
    return  result === '00:00:00' ? '' : result;
  }

  ngOnDestroy() {
    this.timeSub.unsubscribe();
  }
}
