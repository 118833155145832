/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { Component, OnInit } from '@angular/core';
import { LayoutType } from './core/constants/app.config';
import { SplashScreenService } from './core/services/splash-screen.service';
import { ConfigReducer } from './features/base/state-management/config/config.reducer';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  layoutTypes = LayoutType;
  layout = LayoutType.VERTICAL;

  constructor(
    private splashScreen: SplashScreenService, // DO NOT REMOVE
    private configReducer: ConfigReducer
  ) {}

  ngOnInit(): void {
    this.configReducer.selectLayout().subscribe((layout) => {
      this.layout = layout;
    });
  }
}
