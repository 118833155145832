/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../../environments/environment';
import { ConfigEffects } from '../../features/base/state-management/config/config.effects';
import { UserEffects } from '../../features/base/state-management/user/user.effects';
import { RouterEffects } from '../../features/base/state-management/router/router.effects';
import { SnackBarEffects } from '../../features/base/state-management/snack-bar/snack-bar.effects';
import { NavBarEffects } from '../../features/base/state-management/nav-bar/nav-bar.effects';
import { SseEffects } from '../../features/base/state-management/sse/sse.effects';
import { PagingEffects } from '../../features/base/state-management/paging/paging.effects';
import { SyncEffect } from '../../features/base/state-management/sync/sync.effect';
import { MassNotificationEffects } from '../../features/base/state-management/mass-notification/mass-notification.effects';
import { ConfigReducer } from '../../features/base/state-management/config/config.reducer';
import { ConfigActions } from '../../features/base/state-management/config/config.actions';
import { UserReducer } from '../../features/base/state-management/user/user.reducer';
import { UserActions } from '../../features/base/state-management/user/user.actions';
import { AuthActions } from '../../features/auth/state-management/auth.actions';
import { RouterReducer } from '../../features/base/state-management/router/router.reducer';
import { RouterActions } from '../../features/base/state-management/router/router.actions';
import { SnackBarReducer } from '../../features/base/state-management/snack-bar/snack-bar.reducer';
import { SnackBarActions } from '../../features/base/state-management/snack-bar/snack-bar.actions';
import { NavBarReducer } from '../../features/base/state-management/nav-bar/nav-bar.reducer';
import { NavBarActions } from '../../features/base/state-management/nav-bar/nav-bar.actions';
import { SseReducer } from '../../features/base/state-management/sse/sse.reducer';
import { SseActions } from '../../features/base/state-management/sse/sse.actions';
import { PagingReducer } from '../../features/base/state-management/paging/paging.reducer';
import { PagingActions } from '../../features/base/state-management/paging/paging.actions';
import { SyncReducer } from '../../features/base/state-management/sync/sync.reducer';
import { SyncActions } from '../../features/base/state-management/sync/sync.actions';
import { MassNotificationReducer } from '../../features/base/state-management/mass-notification/mass-notification.reducer';
import { MassNotificationActions } from '../../features/base/state-management/mass-notification/mass-notification.actions';

@NgModule({
  imports: [
    StoreModule.forRoot(
      {},
      {
        metaReducers: [
          (reducer) => {
            return (state, action) => {
              // TODO remove hard code
              if ('[USER] Logout user' === action.type) {
                state = {};
              }

              return reducer(state, action);
            };
          },
        ],
      }
    ),
    EffectsModule.forRoot([
      ConfigEffects,
      UserEffects,
      RouterEffects,
      SnackBarEffects,
      NavBarEffects,
      SseEffects,
      PagingEffects,
      SyncEffect,
      MassNotificationEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 30,
      logOnly: environment.production,
      actionsBlocklist: [
        '[USER] Upsert many',
        '[PAST] Replace all',
        '[PAST] End loading',
        '[STOCK] Replace all',
        '[STOCK] End loading',
        '[INBOX] Replace all',
        '[INBOX] End loading',
        '[SSE] Before fire sse',
        '[SSE] Update one',
        '[MESSAGE] Upsert many',
      ],
    }),
  ],
  providers: [
    ConfigReducer,
    ConfigActions,

    UserReducer,
    UserActions,

    AuthActions,

    RouterReducer,
    RouterActions,

    SnackBarReducer,
    SnackBarActions,

    NavBarReducer,
    NavBarActions,

    SseReducer,
    SseActions,

    PagingReducer,
    PagingActions,

    SyncReducer,
    SyncActions,

    MassNotificationReducer,
    MassNotificationActions,
  ],
})
export class StateManagementModule {
  constructor(
    configReducer: ConfigReducer,
    userReducer: UserReducer,
    routerReducer: RouterReducer,
    snackBarReducer: SnackBarReducer,
    navBarReducer: NavBarReducer,
    sseReducer: SseReducer,
    pagingReducer: PagingReducer,
    syncReducer: SyncReducer,
    massNotificationReducer: MassNotificationReducer
  ) {
    configReducer.register();
    userReducer.register();
    routerReducer.register();
    snackBarReducer.register();
    navBarReducer.register();
    sseReducer.register();
    pagingReducer.register();
    syncReducer.register();
    massNotificationReducer.register();
  }
}
