/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, debounceTime, first, map } from 'rxjs/operators';
import { SseActions } from './sse.actions';
import { RegisterListenerPayload, SsePayload } from './sse.payload';
import { SseReducer } from './sse.reducer';
import { AbstractEffects } from '../../../../core/state-management/abstract-effects';
import { SseService } from '../../services/sse.service';

@Injectable()
export class SseEffects extends AbstractEffects<SsePayload> {
  registerListener$ = createEffect(() =>
    this.actions.pipe(
      ofType<any>(this.sseActions.RegisterListener.TYPE),
      map((action) => this.parseAction<RegisterListenerPayload>(action)),
      concatMap(({ completeActions }) => {
        return [new this.sseActions.RestartListener(), ...completeActions];
      })
    )
  );

  unRegisterListener$ = createEffect(() =>
    this.actions.pipe(
      ofType<any>(this.sseActions.UnRegisterListener.TYPE),
      map((action) => this.parseAction(action)),
      concatMap(({ completeActions }) => {
        return [new this.sseActions.RestartListener(), ...completeActions];
      })
    )
  );

  reStartListener$ = createEffect(() =>
    this.actions.pipe(
      ofType<any>(this.sseActions.RestartListener.TYPE),
      debounceTime(200),
      map((action) => this.parseAction<RegisterListenerPayload>(action)),
      concatMap(({ completeActions }) => {
        return this.sseReducer.selectAll().pipe(
          first(),
          concatMap((channels) => {
            this.sseService.registerListener(channels);

            return [...completeActions];
          })
        );
      })
    )
  );

  constructor(
    protected actions: Actions,
    protected sseActions: SseActions,
    protected sseService: SseService,
    protected sseReducer: SseReducer
  ) {
    super(actions, sseActions);
  }
}
