/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MassNotification } from '../mass-notification.state';
import { BaseListComponent } from '../../../../../core/components/list.component';

@Component({
  selector: 'app-advanced-notification-dialog',
  templateUrl: './advanced-notification-dialog.component.html',
  styleUrls: ['./advanced-notification-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvancedNotificationDialogComponent
  extends BaseListComponent
  implements OnInit
{
  constructor(@Inject(MAT_DIALOG_DATA) public data: MassNotification) {
    super();
  }

  ngOnInit(): void {}
}
