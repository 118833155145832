/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserActions } from './user.actions';
import { SetAgentShiftPayload, UserPayload } from './user.payload';
import { Agent, User, UserState } from './user.state';
import { AbstractReducer } from '../../../../core/state-management/abstract-reducer';
import { Select } from '../../../../core/decorators/select.decorator';
import { AbstractAction } from '../../../../core/state-management/abstract-actions';

@Injectable()
export class UserReducer extends AbstractReducer<User, UserState, UserPayload> {
  @Select()
  selectAgent: () => Observable<Agent>;

  get storeName(): string {
    return 'user';
  }

  get reducers(): {
    [p: string]: (action: AbstractAction<any>, state: UserState) => UserState;
  } {
    return {
      [this.actions.SetUserAction.TYPE]: (action, state) => ({
        ...state,
        ...{ agent: action.payload },
      }),
      [this.actions.SetAgentShiftAction.TYPE]: (
        action: AbstractAction<SetAgentShiftPayload>,
        state
      ) => {
        const agentLatestStat = {
          ...state.agent.agentLatestStat,
          ...{ entityType: action.payload.entityType },
        };
        const agent = { ...state.agent, ...{ agentLatestStat } };

        return { ...state, ...{ agent } };
      },
    };
  }

  constructor(protected actions: UserActions) {
    super(actions);
  }
}
