/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Config, ConfigState } from './config.state';
import { ConfigPayload } from './config.payload';
import { ConfigActions } from './config.actions';
import { AbstractReducer } from '../../../../core/state-management/abstract-reducer';
import { Select } from '../../../../core/decorators/select.decorator';
import { appConfig, LayoutType } from '../../../../core/constants/app.config';
import { AbstractEntityState } from '../../../../core/state-management/abstract-state';
import { AbstractAction } from '../../../../core/state-management/abstract-actions';

@Injectable()
export class ConfigReducer extends AbstractReducer<
  Config,
  ConfigState,
  ConfigPayload
> {
  @Select() selectLayout: () => Observable<LayoutType>;
  @Select() selectTimestamp: () => Observable<number>;
  @Select() selectTimezone: () => Observable<string>;
  @Select() selectPauseLimit: () => Observable<number>;
  @Select() selectSelectiveScopes: () => Observable<Record<string, string>>;
  @Select() selectSelectedScope: () => Observable<string>;
  @Select() selectStatusList: () => Observable<string[]>;

  constructor(protected actions: ConfigActions) {
    super(actions);
  }

  get storeName(): string {
    return 'config';
  }

  getInitialState(): AbstractEntityState<Config> {
    return this.adapter.getInitialState({
      ...{ isLoading: false },
      ...appConfig,
      ...{ selectedScope: 'default' },
    });
  }

  get reducers(): {
    [p: string]: (
      action: AbstractAction<ConfigPayload>,
      state: ConfigState
    ) => ConfigState;
  } {
    return {
      [this.actions.SetConfigAction.TYPE]: (
        action: AbstractAction<ConfigPayload>,
        state: ConfigState
      ) => {
        return {
          ...state,
          ...action.payload,
        };
      },
    };
  }
}
