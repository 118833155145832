/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  constructor(
    protected matSnackBar: MatSnackBar
  ) {
  }

  open(message: string, action: string = 'Close', config?: MatSnackBarConfig) {
    return this.matSnackBar.open(message, action, {...{
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    }, ...config});
  }
}
