/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { AbstractEffects } from '../../../../core/state-management/abstract-effects';
import { SnackBarPayload } from './snack-bar.payload';
import { SnackBarActions } from './snack-bar.actions';
import { SnackBarService } from '../../../../core/services/snack-bar.service';

@Injectable()
export class SnackBarEffects extends AbstractEffects<SnackBarPayload> {
  showError$ = createEffect(() =>
    this.actions.pipe(
      ofType<any>(this.snackBarActions.ShowErrorAction.TYPE),
      map((action) => this.parseAction<SnackBarPayload>(action)),
      switchMap(({ payload, completeActions }) => {
        this.snackBarService.open(payload.message);

        return completeActions;
      })
    )
  );

  showSuccess$ = createEffect(() =>
    this.actions.pipe(
      ofType<any>(this.snackBarActions.ShowSuccessAction.TYPE),
      map((action) => this.parseAction<SnackBarPayload>(action)),
      switchMap(({ payload, completeActions }) => {
        const { message, action, config } = payload;

        this.snackBarService.open(message, action, config);

        return completeActions;
      })
    )
  );

  constructor(
    protected actions: Actions,
    protected snackBarActions: SnackBarActions,
    protected snackBarService: SnackBarService
  ) {
    super(actions, snackBarActions);
  }
}
