/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { Component, OnInit } from '@angular/core';
import {RoutesProviderService} from '../../../core/services/routes-provider.service';
import {NavBarActions} from '../../../features/base/state-management/nav-bar/nav-bar.actions';
import {UserReducer} from '../../../features/base/state-management/user/user.reducer';
import {BaseComponent} from '../../../core/components/base.component';
import {MatDialog} from '@angular/material/dialog';
import {HomeLogoutDialogComponent} from '../../../features/home/ui/components/home-logout-dialog/home-logout-dialog.component';
import {RouterActions} from '../../../features/base/state-management/router/router.actions';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-tool-bar',
  templateUrl: './tool-bar.component.html',
  styleUrls: ['./tool-bar.component.scss']
})
export class ToolBarComponent extends BaseComponent implements OnInit {
  agent = this.userReducer.selectAgent();

  constructor(
    protected dialog: MatDialog,
    protected navBarActions: NavBarActions,
    protected routerActions: RouterActions,
    protected userReducer: UserReducer,
    protected routesProviderService: RoutesProviderService,
  ) {
    super();
  }

  ngOnInit(): void {
  }

  logOut() {
    this.agent.pipe(first()).subscribe(agent => {
      if (agent.agentLatestStat.entityType === 'shift_start') {
        this.subscription = this.dialog.open(HomeLogoutDialogComponent).afterClosed().subscribe(result => {
          if (result) {
            new this.routerActions.NavigateAction({commands: [this.routesProviderService.logout]}).dispatch();
          }
        });
      } else {
        new this.routerActions.NavigateAction({commands: [this.routesProviderService.logout]}).dispatch();
      }
    });
  }

  toggle() {
    new this.navBarActions.ToggleAction().dispatch();
  }
}
