/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { Injectable } from '@angular/core';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Observable } from 'rxjs';
import { PagingActions } from './paging.actions';
import { PagingPayload } from './paging.payload';
import { Paging, PagingState } from './paging.state';
import { AbstractReducer } from '../../../../core/state-management/abstract-reducer';
import { AbstractAction } from '../../../../core/state-management/abstract-actions';

@Injectable()
export class PagingReducer extends AbstractReducer<
  Paging,
  PagingState,
  PagingPayload
> {
  get storeName(): string {
    return 'paging';
  }

  protected adapter: EntityAdapter<Paging> = createEntityAdapter<Paging>({
    selectId: (entity) => entity.key,
    sortComparer: false,
  });

  constructor(protected actions: PagingActions) {
    super(actions);
  }

  get reducers(): {
    [p: string]: (
      action: AbstractAction<any>,
      state: PagingState
    ) => PagingState;
  } {
    return {};
  }

  selectByKey(key: string): Observable<Paging> {
    return this.select(
      (state: PagingState) => state.entities[key] ?? null
    ) as any;
  }
}
