<h2 mat-dialog-title>Please select option</h2>
<mat-dialog-content>
  <mat-radio-group fxLayout="column" fxLayoutAlign="space-evenly" [(ngModel)]="response">
    <mat-radio-button style="text-transform: capitalize" *ngFor="let reason of reasons | async;"  [value]="reason">{{reason}}</mat-radio-button>
  </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="response" [disabled]="!response" cdkFocusInitial>Confirm</button>
</mat-dialog-actions>
