/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatPaginatorModule} from '@angular/material/paginator';
import {EmptyComponent} from './empty/empty.component';
import {SpinnerComponent} from './spinner/spinner.component';
import { AvatarComponent } from './avatar/avatar.component';
import {UserAvatarComponent} from './user-avatar/user-avatar.component';
import { PaginatorComponent } from './paginator/paginator.component';
import {ConfirmDialogComponent} from './confirm/confirm-dialog.component';
import {NgIf} from '@angular/common';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  declarations: [
    SpinnerComponent,
    EmptyComponent,
    AvatarComponent,
    UserAvatarComponent,
    PaginatorComponent,
    ConfirmDialogComponent,
  ],
  imports: [
    MatIconModule,
    MatListModule,
    MatPaginatorModule,
    MatDialogModule,
    MatButtonModule,
    NgIf,
  ],
  exports: [
    SpinnerComponent,
    EmptyComponent,
    AvatarComponent,
    UserAvatarComponent,
    PaginatorComponent,
    ConfirmDialogComponent,
  ]
})
export class ComponentModule {

}
