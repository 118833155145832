/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { FormModel } from '../form/model';

export abstract class AbstractService {
  protected api = `${environment.api}/hoi/console`;

  protected constructor(protected http: HttpClient) {}

  abstract get uri(): string;

  getUrl(uri: string): string {
    let url = `${this.api}/${this.uri}/${uri}`;

    if (!environment.production) {
      url += url.includes('?')
        ? '&XDEBUG_SESSION_START=18394'
        : '?XDEBUG_SESSION_START=18394';
    }

    return url;
  }

  get(uri: string = '') {
    const url = this.getUrl(uri);

    return this.http.get(url, {
      headers: {},
    });
  }

  post<T extends FormModel>(uri: string, data: T) {
    const url = this.getUrl(uri);

    return this.http.post(url, data, {
      headers: {},
    });
  }
}
