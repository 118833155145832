/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, map} from 'rxjs/operators';
import {AbstractEffects} from '../../../core/state-management/abstract-effects';
import {SlsTrackingService} from '../services/sls-tracking.service';
import {SlsTrackingActions} from './sls-tracking.actions';
import {SlsTrackingAuthenticatePayload, SlsTrackingAuthPayload} from './sls-tracking.payload';

@Injectable()
export class SlsTrackingEffects extends AbstractEffects<SlsTrackingAuthenticatePayload> {

  updateToken$ = createEffect(() => this.actions.pipe(
    ofType<any>(this.slsTrackingActions.UpdateTokenAction.TYPE),
    map(action => this.parseAction<SlsTrackingAuthPayload>(action)),
    concatMap(({payload, completeActions}) => {
      this.slsTrackingService.updateToken(payload);
      return [...completeActions];
    }),
  ));

  Logout$ = createEffect(() => this.actions.pipe(
    ofType<any>(this.slsTrackingActions.LogoutAction.TYPE),
    map(action => this.parseAction(action)),
    concatMap(({payload, completeActions}) => {
      this.slsTrackingService.logout();
      return [...completeActions];
    }),
  ));

  constructor(
    protected actions: Actions,
    protected slsTrackingActions: SlsTrackingActions,
    protected slsTrackingService: SlsTrackingService,
  ) {
    super(actions, slsTrackingActions);
  }
}
