/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, first, map } from 'rxjs/operators';
import { PagingActions } from './paging.actions';
import {
  PagingInitPayload,
  PagingLengthPayload,
  PagingPageIndexPayload,
  PagingPayload,
} from './paging.payload';
import { PagingReducer } from './paging.reducer';
import { AbstractEffects } from '../../../../core/state-management/abstract-effects';
import {
  PAGING_INIT_PAGE_INDEX,
  PAGING_INIT_PAGE_SIZE,
} from '../../../../core/constants/paging';

@Injectable()
export class PagingEffects extends AbstractEffects<PagingPayload> {
  init$ = createEffect(() =>
    this.actions.pipe(
      ofType<any>(this.pagingActions.InitAction.TYPE),
      map((action) => this.parseAction<PagingInitPayload>(action)),
      concatMap(
        ({
          payload: {
            key,
            pageIndex = PAGING_INIT_PAGE_INDEX,
            pageSize = PAGING_INIT_PAGE_SIZE,
          },
          completeActions,
        }) => {
          return this.pagingReducer.selectByKey(key).pipe(
            first(),
            concatMap((_) => {
              return [
                new this.pagingActions.AddOneAction({
                  key,
                  pageIndex,
                  pageSize,
                  length: 0,
                }),
                ...completeActions,
              ];
            })
          );
        }
      )
    )
  );

  setCount$ = createEffect(() =>
    this.actions.pipe(
      ofType<any>(this.pagingActions.SetLengthAction.TYPE),
      map((action) => this.parseAction<PagingLengthPayload>(action)),
      concatMap(({ payload, completeActions }) => {
        return [
          new this.pagingActions.UpdateOneAction({
            id: payload.key,
            changes: { length: payload.length },
          }),
          ...completeActions,
        ];
      })
    )
  );

  setPageIndex$ = createEffect(() =>
    this.actions.pipe(
      ofType<any>(this.pagingActions.SetPageIndex.TYPE),
      map((action) => this.parseAction<PagingPageIndexPayload>(action)),
      concatMap(({ payload, completeActions }) => {
        return [
          new this.pagingActions.UpdateOneAction({
            id: payload.key,
            changes: { pageIndex: payload.pageIndex },
          }),
          ...completeActions,
        ];
      })
    )
  );

  constructor(
    protected actions: Actions,
    protected pagingActions: PagingActions,
    protected pagingReducer: PagingReducer
  ) {
    super(actions, pagingActions);
  }
}
