/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { Injectable } from '@angular/core';
import { ConfigPayload } from './config.payload';
import { Store } from '@ngrx/store';
import { Config } from './config.state';
import { AbstractActions } from '../../../../core/state-management/abstract-actions';

@Injectable()
export class ConfigActions extends AbstractActions<ConfigPayload> {
  SetConfigAction = this.action<ConfigPayload>(
    `${this.getEntity()} Set config`
  );

  constructor(store: Store<Config>) {
    super(store);
  }

  getEntity(): string {
    return '[CONFIG]';
  }
}
