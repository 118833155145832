/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../../core/components/base.component';
import {UserReducer} from '../../../../base/state-management/user/user.reducer';
import {UserActions} from '../../../../base/state-management/user/user.actions';

@Component({
  selector: 'app-home-logout-dialog',
  templateUrl: './home-logout-dialog.component.html',
  styleUrls: ['./home-logout-dialog.component.scss']
})
export class HomeLogoutDialogComponent extends BaseComponent implements OnInit {
  agent = this.userReducer.selectAgent();

  constructor(
    protected userActions: UserActions,
    protected userReducer: UserReducer,
  ) {
    super();
  }

  ngOnInit(): void {
    new this.userActions.GetAgentShiftAction().dispatch();
  }
}
