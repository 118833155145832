/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { filter, finalize, first, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AbstractService } from '../../../core/services/abstract.service';
import { ChannelList } from '../state-management/sse/sse.state';
import { SseActions } from '../state-management/sse/sse.actions';
import { SseReducer } from '../state-management/sse/sse.reducer';

@Injectable({
  providedIn: 'root',
})
export class SseService extends AbstractService {
  protected readonly INTERVAL = 10000;

  protected timer: any;
  protected channelList: ChannelList;
  protected params;

  constructor(
    http: HttpClient,
    protected zone: NgZone,
    protected sseAction: SseActions,
    protected sseReducer: SseReducer
  ) {
    super(http);
  }

  get uri(): string {
    return 'sse';
  }

  protected createSource() {
    this.destroySource();

    if (this.channelList.length) {
      this.timer = this.zone.runOutsideAngular(() =>
        setTimeout(() => this.run(), this.INTERVAL)
      );
    }
  }

  protected destroySource() {
    this.timer && clearTimeout(this.timer);
    this.timer = null;
  }

  protected run() {
    this.sseReducer
      .selectIsEnabled()
      .pipe(
        filter((isEnabled) => isEnabled),
        switchMap(() => this.sseReducer.selectAll()),
        switchMap((channels) => {
          return of(
            channels.filter((channel) => {
              const { interval = 0 } = channel.options || {};

              return (
                interval <= 0 ||
                !channel.lastRun ||
                Math.floor(Date.now() / 1000) - channel.lastRun > interval
              );
            })
          );
        }),
        first(),
        tap(() =>
          new this.sseAction.BeforeFireSseAction(this.params).dispatch()
        )
      )
      .subscribe((channels) => {
        this.params = this.channelToParams(channels);

        this.get('?' + new URLSearchParams(this.params))
          .pipe(
            first(),
            finalize(() => this.createSource())
          )
          .subscribe((data) => {
            Object.keys(data).forEach((key) => {
              const channel = this.channelList.find((c) => c.eventName === key);

              new this.sseAction.UpdateOneAction({
                // @ts-ignore
                id: key,
                changes: { lastRun: Math.floor(Date.now() / 1000) },
              }).dispatch();

              channel && this.zone.run(() => channel.listener(data[key]));
            });
          });
      });
  }

  registerListener(channels: ChannelList) {
    this.channelList = channels;
    this.destroySource();

    if (!channels.length) {
      return;
    }

    this.params = this.channelToParams(channels);
    this.zone.runOutsideAngular(() => this.run());
  }

  protected channelToParams(channels) {
    return channels.reduce((carry, channel) => {
      if (channel.params) {
        Object.keys(channel.params).forEach((key) => {
          carry[`${channel.eventName}[${key}]`] = channel.params[key];
        });
      } else {
        carry[`${channel.eventName}`] = true;
      }

      return carry;
    }, {});
  }
}
