/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { AbstractService } from './abstract.service';
import { ConfigActions } from '../../features/base/state-management/config/config.actions';
import { UserActions } from '../../features/base/state-management/user/user.actions';

@Injectable({
  providedIn: 'root',
})
export class AppInitService extends AbstractService {
  constructor(
    http: HttpClient,
    protected service: TranslateService,
    protected configActions: ConfigActions,
    protected userActions: UserActions,
    protected actions: Actions
  ) {
    super(http);
  }

  get uri(): string {
    return 'init';
  }

  init() {
    return this.get()
      .toPromise()
      .then((data: any) => {
        const {
          user,
          timezone,
          timestamp,
          pauseLimit,
          workStatusList: statusList,
          selectiveScopesList: selectiveScopes,
          lang: { use, translations },
        } = data;

        this.service.setTranslation(use, translations);
        this.service.use(use);

        setTimeout(() =>
          new this.configActions.SetConfigAction(
            {
              timezone,
              timestamp,
              pauseLimit,
              statusList,
              selectiveScopes,
            },
            [new this.userActions.SetUserAction(user)]
          ).dispatch()
        ); // TODO find better solution

        return this.actions
          .pipe(ofType(this.userActions.AfterUserSetAction.TYPE), first())
          .toPromise();
      })
      .catch((...rest) => {
        console.error('App Init');
        console.dir(rest);
      });
  }
}
