/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { BaseComponent } from './base.component';
import { AbstractState } from '../state-management/abstract-state';

export class BaseListComponent extends BaseComponent {
  trackByFn(index, entity: AbstractState) {
    return entity.id;
  }
}
