/**
 * Sydykov Chyngyz
 * chyngyz6@gmail.com
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { interval, Observable } from 'rxjs';
import { first, map, withLatestFrom } from 'rxjs/operators';
import { AbstractService } from '../../../core/services/abstract.service';
import { ConfigReducer } from '../state-management/config/config.reducer';

@Injectable({
  providedIn: 'root',
})
export class AgentStatService extends AbstractService {
  protected intervalBetweenPause;
  protected timerBetweenPause = 0;
  protected isTimerStart = false;

  constructor(
    http: HttpClient,
    protected configReducer: ConfigReducer
  ) {
    super(http);
  }

  get uri(): string {
    return 'agent-stat';
  }

  saveAgentStat(payload): Observable<any> {
    return this.post(`${payload.entityType}`, payload);
  }

  getShift() {
    return this.get('shift-info');
  }

  startTimerBetweenPause() {
    if (!this.checkTimerBetweenPause || this.isTimerStart) {
      return;
    }

    this.isTimerStart = true;
    this.intervalBetweenPause = interval(1000)
      .pipe(withLatestFrom(this.configReducer.selectPauseLimit()))
      .subscribe(([, pauseLimit]) => {
        if (this.timerBetweenPause >= pauseLimit) {
          this.stopTimerBetweenPause();

          return;
        }

        this.timerBetweenPause++;
      });
  }

  stopTimerBetweenPause() {
    this.intervalBetweenPause && this.intervalBetweenPause.unsubscribe();
    this.timerBetweenPause = 0;
    this.isTimerStart = false;
  }

  checkTimerBetweenPause() {
    return this.configReducer.selectPauseLimit().pipe(
      first(),
      map((pauseLimit) => {
        return (
          this.timerBetweenPause === 0 || this.timerBetweenPause >= pauseLimit
        );
      })
    );
  }

  setSecondsBetweenPause(seconds) {
    if (this.timerBetweenPause !== 0) {
      return;
    }

    this.timerBetweenPause = seconds;
  }

  getHMS() {
    return this.configReducer.selectPauseLimit().pipe(
      first(),
      map((pauseLimit) => {
        const time = pauseLimit - this.timerBetweenPause;
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time - hours * 3600) / 60);
        const seconds = time - hours * 3600 - minutes * 60;

        let hourString = hours.toString();
        let minuteString = minutes.toString();
        let secondString = seconds.toString();

        if (hours < 10) {
          hourString = '0' + hours;
        }

        if (minutes < 10) {
          minuteString = '0' + minutes;
        }

        if (seconds < 10) {
          secondString = '0' + seconds;
        }

        return `${hourString}:${minuteString}:${secondString}`;
      })
    );
  }
}
