/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { concatMap, map, switchMap } from 'rxjs/operators';
import { SseActions } from '../sse/sse.actions';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbstractEffects } from '../../../../core/state-management/abstract-effects';
import { MassNotificationPayload } from './mass-notification.payload';
import {
  MassNotification,
  MassNotificationType,
} from './mass-notification.state';
import { AdvancedNotificationDialogComponent } from './advanced-notification-dialog/advanced-notification-dialog.component';
import { MassNotificationActions } from './mass-notification.actions';
import { MassNotificationService } from './mass-notification.service';

@Injectable()
export class MassNotificationEffects extends AbstractEffects<MassNotificationPayload> {
  registerListener$ = createEffect(() =>
    this.ofType(this.massNotificationActions.RegisterListener.TYPE).pipe(
      concatMap(() => {
        return [
          new this.sseActions.RegisterListener({
            eventName: 'sseMassNotification',
            listener: (massNotification: MassNotification) => {
              const { id, title, content, type } = massNotification;

              if (type === MassNotificationType.SIMPLE) {
                this.snackBar
                  .open(content, 'Got It!', {
                    horizontalPosition: 'right',
                    verticalPosition: 'top',
                  })
                  .onAction()
                  .subscribe(() => {
                    new this.massNotificationActions.AcceptListener({
                      id,
                    }).dispatch();
                  });
              } else {
                this.dialog
                  .open(AdvancedNotificationDialogComponent, {
                    data: {
                      title,
                      content,
                    },
                    minWidth: '800px',
                    disableClose: true,
                  })
                  .afterClosed()
                  .subscribe(() => {
                    new this.massNotificationActions.AcceptListener({
                      id,
                    }).dispatch();
                  });
              }
            },
            options: {
              interval: 180, // 3 min
            },
          }),
        ];
      })
    )
  );

  acceptListener$ = createEffect(() =>
    this.ofType(this.massNotificationActions.AcceptListener.TYPE).pipe(
      map((action) => this.parseAction(action)),
      switchMap(({ payload, completeActions }) => {
        return this.massNotificationService.accept(payload.id).pipe(
          concatMap(() => {
            return [...completeActions];
          })
        );
      })
    )
  );

  constructor(
    protected actions: Actions,
    protected sseActions: SseActions,
    protected massNotificationActions: MassNotificationActions,
    protected massNotificationService: MassNotificationService,
    protected snackBar: MatSnackBar,
    protected dialog: MatDialog
  ) {
    super(actions, massNotificationActions);
  }
}
