/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../../../core/services/abstract.service';

@Injectable({
  providedIn: 'root',
})
export class UserService extends AbstractService {
  static readonly PERMISSION_AGENT_SUPER = 1;
  static readonly PERMISSION_AGENT_VIEW_SUPER = 2;
  static readonly PERMISSION_AGENT_VIEW_NEW = 4;

  constructor(http: HttpClient) {
    super(http);
  }

  get uri(): string {
    return 'user';
  }

  loadData(id: number, conversationId: number): Observable<any> {
    return this.get(`${id}/data/${conversationId}`);
  }

  hasPermission(agentPermission: number, permission: number) {
    // tslint:disable-next-line:no-bitwise
    return agentPermission & permission;
  }
}
