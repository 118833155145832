<h2 mat-dialog-title>Your shift statistics {{(agent | async).displayName}}</h2>
<mat-dialog-content fxLayout="column" fxLayoutAlign="space-evenly center">
  <span>You have sent this number of messages:</span>
  <span style="font-size: 100pt">
    {{ (agent|async).countShiftPerform }}
  </span>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Logout</button>
</mat-dialog-actions>
