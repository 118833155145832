<div fxLayoutAlign="space-between center" class="timer-component-container">
  <div class="ticker-container" fxLayout="row">
    <app-tool-bar-ticker timerId="mainTicker" #mainTicker class="mainTicker"></app-tool-bar-ticker>
    <app-tool-bar-ticker timerId="breakTicker" #breakTicker class="breakTicker"></app-tool-bar-ticker>
  </div>
  <div class="button-container">
    <div *ngIf="timerStatus == 'started' || timerStatus == 'paused'; then timerStarted else startTimer"></div>

    <ng-template #timerStarted>
      <div *ngIf="timerStatus == 'paused'; then showResume else showPause"></div>

      <ng-template #showResume>
        <button
          mat-raised-button
          color="warning"
          [disabled]="isLoading | async"
          (click)="resumeBreakTimer()"
        >resume</button>
      </ng-template>

      <ng-template #showPause>
        <button
          mat-raised-button
          color="warning"
          [disabled]="(isLoading | async) || !isTimersSet"
          (click)="pauseBreakTimer()"
        >pause</button>
      </ng-template>

      <button
        *ngIf="timerStatus == 'started'"
        mat-raised-button
        color="success"
        [disabled]="isLoading | async"
        (click)="endMainTimer()"
      >end</button>
    </ng-template>

    <ng-template #startTimer>
      <button
        mat-raised-button
        color="success"
        [disabled]="isLoading | async"
        (click)="startMainTimer()"
      >start</button>
    </ng-template>
  </div>
</div>
