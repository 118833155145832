/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { Injectable } from '@angular/core';
import { routeNames } from '../constants/app.route-names';

@Injectable({
  providedIn: 'root',
})
export class RoutesProviderService {
  get auth() {
    return `/${routeNames.auth}`;
  }

  get logout() {
    return `${this.auth}/${routeNames.logout}`;
  }

  get dashboard() {
    return `/${routeNames.dashboard}`;
  }

  get dashboardStart() {
    return `${this.dashboard}/${routeNames.dashboardStart}`;
  }

  get dashboardConversation() {
    return `${this.dashboard}/${routeNames.conversation}`;
  }

  dashboardConversationId(id: number) {
    return `${this.dashboardConversation}/${id}`;
  }

  dashboardNote(conversationId: number, userId: number) {
    return `${this.dashboardConversation}/${routeNames.note}/${conversationId}/${userId}`;
  }

  dashboardMeetUp(conversationId: number, userId: number) {
    return `${this.dashboardConversation}/${routeNames.meetUp}/${conversationId}/${userId}`;
  }

  get home() {
    return `/${routeNames.home}`;
  }

  get agent() {
    return `${this.home}/${routeNames.agent}`;
  }

  get stock() {
    return `${this.home}/${routeNames.stock}`;
  }

  get inbox() {
    return `${this.home}/${routeNames.inbox}`;
  }

  get follow() {
    return `${this.home}/${routeNames.follow}`;
  }

  get past() {
    return `${this.home}/${routeNames.past}`;
  }

  get archive() {
    return `${this.home}/${routeNames.archive}`;
  }

  get note() {
    return `${this.home}/${routeNames.note}`;
  }

  customer(type: string) {
    return `${this.home}/${routeNames.customer}/${type}`;
  }

  customerMatch(type: string, userId: number) {
    return `${this.customer(type)}/${userId}`;
  }

  stockMessages(id) {
    return `${this.stock}/${id}`;
  }

  inboxMessages(id) {
    return `${this.inbox}/${id}`;
  }

  pastMessages(id) {
    return `${this.past}/${id}`;
  }

  fakeNote(conversationId, userId) {
    return `${this.note}/${conversationId}/${userId}`;
  }

  meetUp(conversationId, userId) {
    return `${this.home}/${routeNames.meetUp}/${conversationId}/${userId}`;
  }

  get stat() {
    return `/${routeNames.stat}`;
  }
}
