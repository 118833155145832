/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { Component } from '@angular/core';
import { ConfigReducer } from '../../../../base/state-management/config/config.reducer';

@Component({
  selector: 'app-home-pause-dialog',
  templateUrl: './home-pause-dialog.component.html',
  styleUrls: ['./home-pause-dialog.component.scss'],
})
export class HomePauseDialogComponent {
  reasons = this.configReducer.selectStatusList();
  response = '';

  constructor(protected configReducer: ConfigReducer) {}
}
