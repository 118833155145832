/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { NavBarActions } from './nav-bar.actions';
import { NavBarPayload } from './nav-bar.payload';
import { AbstractEffects } from '../../../../core/state-management/abstract-effects';

@Injectable()
export class NavBarEffects extends AbstractEffects<NavBarPayload> {
  constructor(
    protected actions: Actions,
    protected navBarActions: NavBarActions
  ) {
    super(actions, navBarActions);
  }
}
