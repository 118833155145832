/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthPayload, LoginPayload } from './auth.payload';
import { Auth } from './auth.state';
import { AbstractActions } from '../../../core/state-management/abstract-actions';

@Injectable()
export class AuthActions extends AbstractActions<AuthPayload> {
  LoginAction = this.action<LoginPayload>(`${this.getEntity()} Login`);

  LogoutAction = this.action(`${this.getEntity()} Logout`);

  InternalLogoutAction = this.action(`${this.getEntity()} Internal Logout`);

  getEntity(): string {
    return '[LOGIN]';
  }

  constructor(store: Store<Auth>) {
    super(store);
  }
}
