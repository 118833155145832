/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, finalize, map, tap } from 'rxjs/operators';
import { UserActions } from './user.actions';

import {
  LoadUserDataPayload,
  SetAgentShiftPayload,
  UserPayload,
} from './user.payload';
import { AbstractEffects } from '../../../../core/state-management/abstract-effects';
import { LayoutType } from '../../../../core/constants/app.config';
import { ConfigActions } from '../config/config.actions';
import { AuthActions } from '../../../auth/state-management/auth.actions';
import { UserService } from '../../services/user.service';
import { AgentStatService } from '../../services/agent-stat.service';
import { SlsTrackingService } from '../../../../tools/sls-tracking/services/sls-tracking.service';
import { RoutesProviderService } from '../../../../core/services/routes-provider.service';
import { RouterActions } from '../router/router.actions';

@Injectable()
export class UserEffects extends AbstractEffects<UserPayload> {
  loginUser$ = createEffect(() =>
    this.actions.pipe(
      ofType<any>(this.userActions.LoginUserAction.TYPE),
      map((action) => this.parseAction<UserPayload>(action)),
      concatMap(({ payload, completeActions }) => {
        return [
          new this.userActions.SetUserAction(payload),
          ...completeActions,
        ];
      })
    )
  );

  logoutUser$ = createEffect(() =>
    this.actions.pipe(
      ofType<any>(this.userActions.LogoutUserAction.TYPE),
      map((action) => this.parseAction<UserPayload>(action)),
      concatMap(({ completeActions }) => {
        return [new this.userActions.SetUserAction(null), ...completeActions];
      })
    )
  );

  userSet$ = createEffect(() =>
    this.ofType<UserPayload>(this.userActions.SetUserAction.TYPE).pipe(
      concatMap(({ payload, completeActions }) => {
        const { token = null, isSuper = false } = (payload || {}) as any;

        localStorage.setItem('console_auth', token);

        return [
          new this.configActions.SetConfigAction({
            layout: isSuper ? LayoutType.VERTICAL : LayoutType.BLANK,
          }),
          new this.userActions.AfterUserSetAction(payload),
          ...completeActions,
        ];
      })
    )
  );

  afterUserSet$ = createEffect(() =>
    this.ofType<UserPayload>(this.userActions.AfterUserSetAction.TYPE).pipe(
      concatMap(({ payload, completeActions }) => {
        if (
          payload &&
          this.slsTrackingService.isSlsTrackingOn &&
          this.slsTrackingService.isAuthExpired()
        ) {
          return [
            new this.routerActions.NavigateAction({
              commands: [this.routesProviderService.logout],
            }),
            new this.authActions.InternalLogoutAction(),
          ];
        }

        if (payload && payload['id']) {
          this.slsTrackingService.startUpdateToken();
        }

        return [...completeActions];
      })
    )
  );

  loadUserData$ = createEffect(() =>
    this.actions.pipe(
      ofType<any>(this.userActions.LoadUserDataAction.TYPE),
      map((action) => this.parseAction<LoadUserDataPayload>(action)),
      concatMap(({ payload, completeActions }) => {
        return this.userService
          .loadData(payload.id, payload.conversationId)
          .pipe(
            concatMap((data) => {
              return [
                new this.userActions.UpsertOneAction(data),
                ...completeActions,
              ];
            }),
            finalize(() =>
              new this.userActions.UserDataLoadedAction().dispatch()
            )
          );
      })
    )
  );

  setAgentShift$ = createEffect(() =>
    this.ofType<SetAgentShiftPayload>(
      this.userActions.SetAgentShiftAction.TYPE
    ).pipe(
      tap(() => new this.userActions.StartLoadingAction().dispatch()),
      concatMap(({ payload, completeActions }) => {
        if (
          payload &&
          this.slsTrackingService.isSlsTrackingOn &&
          this.slsTrackingService.isAuthExpired()
        ) {
          return [new this.authActions.InternalLogoutAction()];
        }

        return this.slsTrackingService.sendEvent(payload.entityType).pipe(
          concatMap((data) => {
            return this.agentService.saveAgentStat(payload).pipe(
              concatMap(() => {
                return [...completeActions];
              }),
              finalize(() => new this.userActions.EndLoadingAction().dispatch())
            );
          }),
          catchError((error) => {
            return this.agentService.saveAgentStat(payload).pipe(
              concatMap(() => {
                return [...completeActions];
              }),
              finalize(() => new this.userActions.EndLoadingAction().dispatch())
            );
          })
        );
      })
    )
  );

  getAgentSift$ = createEffect(() =>
    this.ofType(this.userActions.GetAgentShiftAction.TYPE).pipe(
      concatMap(({ completeActions }) => {
        return this.agentService.getShift().pipe(
          concatMap((agent) => {
            return [
              new this.userActions.SetUserAction(agent),
              ...completeActions,
            ];
          })
        );
      })
    )
  );

  constructor(
    protected actions: Actions,
    protected configActions: ConfigActions,
    protected userActions: UserActions,
    protected authActions: AuthActions,
    protected userService: UserService,
    protected agentService: AgentStatService,
    protected slsTrackingService: SlsTrackingService,
    protected routesProviderService: RoutesProviderService,
    protected routerActions: RouterActions
  ) {
    super(actions, userActions);
  }
}
